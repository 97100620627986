module.exports = {
    webApp: 'Portal',
    company: 'ThreatER',
    companyLong: 'ThreatER',
    instance: 'Enforcer',
    instancePlural: 'Enforcers',
    software: 'Enforce',
    urlSupport: 'https://support.threater.com',
    urlTermsOfService: 'https://www.threater.com/terms-of-service',
}
