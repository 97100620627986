import Vue from 'vue'
import vuex from '@/store/vuex'
import store from '@/store/vuex'
import router from '@/router'
import VueAxios from 'vue-axios'
import axios from 'axios'
import branding from '/branding.config.js'

axios.defaults.baseURL = vuex.getters['base/baseURL']
axios.defaults.headers.common[branding.instance] = 'true'

axios.interceptors.request.use(
    config => {
        config.headers['Authorization'] = vuex.getters['auth/bearerToken']
        return Promise.resolve(config)
    },
    error => {
        return Promise.reject(error)
    },
)

// TODO: remove axios sleep
export function sleep(timeoutMs = 5000) {
    // console.log('remember to remove `sleep`')
    return new Promise((resolve) => setTimeout(resolve, timeoutMs))
    // return new Promise((resolve) => setTimeout(resolve, process.env.VUE_APP_AXIOS_DELAY))
}

axios.interceptors.response.use(
    async (response) => {
        // console.log('axios.config interceptors process.env.NODE_ENV', process.env.NODE_ENV)
        // console.log('axios.config interceptors process.env.VUE_APP_AXIOS_DELAY', process.env.VUE_APP_AXIOS_DELAY)
        // TODO: remove axios sleep
        if (process.env.NODE_ENV === 'development') {
            // await sleep()
            await sleep(0)
            // await sleep(5000)
            // await sleep(2000)
        }

        return Promise.resolve(response)
    },
    error => {
        // console.log('axios.config interceptors error', error)
        let originalRequest = error.config
        // console.log('originalRequest', originalRequest)

        let configUrl = error.config.url

        if (error.response.status === 403 && configUrl.includes('auth/instance/login')) {
            console.log('foo')
        } else if (error.response.status === 504) {
            vuex.dispatch({
                type: 'flash/addFlash',
                title: 'Gateway Timeout',
                flashType: 'error',
            })

        } else if (error.response.status === 401) {
            if (configUrl.includes('auth/jwt/refresh')) {
                console.log('401 not a refresh')
                store.dispatch('auth/setNextRedirect', router.currentRoute).then(r => {})

                router.push({
                    name: 'authRedirect',
                })
            } else {
                console.log('401 refresh')

                originalRequest._retry = true
                return Vue.axios.get('/auth/jwt/refresh/' + vuex.getters['auth/refreshToken'])
                    // return Vue.axios.get('/auth/jwt/refresh/' + vuex.getters.jwt.refreshToken)
                    .then(response => {
                        console.log('got refresh response. write down info', response.data)
                        vuex.commit('auth/SET_JWT', response.data)

                        // vuex.commit('SET_ACCESS_TOKEN', response.data.accessToken)
                        // vuex.commit('SET_REFRESH_TOKEN', response.data.refreshToken)
                        // vuex.commit('SET_CLAIM_EXPIRES', response.data.claims.exp)
                        // vuex.commit('SET_DEAUTHORIZED', true)

                        originalRequest.headers['Authorization'] = vuex.getters['auth/bearerToken']
                        console.log('retry the request that errored out')
                        return Vue.axios(originalRequest) // retry the request that errored out
                    })
                    .catch(error => {
                        console.log('Error', error)
                        if (error.response.status === 401) {
                            console.log('refresh expired')
                        }
                    })
            }
        } else {
            if (error.response) {
                console.log('if (error.response)', error)
                let errors = error.response.data.extra.errors

                errors.forEach(function(thisError) {
                    vuex.dispatch({
                        type: 'flash/addFlash',
                        title: thisError.message,
                        flashType: 'error',
                    })
                })
            }
        }
        return Promise.reject(error)
    },
)
/*
 axios.interceptors.response.use(
 response => {
 // console.log('axios.config interceptors response', response)
 return Promise.resolve(response)
 },
 error => {
 // console.log('axios.config interceptors error', error)
 let originalRequest = error.config
 // console.log('originalRequest', originalRequest)

 let configUrl = error.config.url

 if (error.response.status === 403 && configUrl.includes('auth/instance/login')) {
 console.log('foo')
 } else if (error.response.status === 504) {
 vuex.dispatch({
 type: 'flash/addFlash',
 title: 'Gateway Timeout',
 flashType: 'error',
 })

 } else if (error.response.status === 401) {
 if (configUrl.includes('auth/jwt/refresh')) {
 console.log('401 not a refresh')
 store.dispatch('auth/setNextRedirect', router.currentRoute).then(r => {})

 router.push({
 name: 'authRedirect',
 })
 } else {
 console.log('401 refresh')

 originalRequest._retry = true
 return Vue.axios.get('/auth/jwt/refresh/' + vuex.getters['auth/refreshToken'])
 // return Vue.axios.get('/auth/jwt/refresh/' + vuex.getters.jwt.refreshToken)
 .then(response => {
 console.log('got refresh response. write down info', response.data)
 vuex.commit('auth/SET_JWT', response.data)

 // vuex.commit('SET_ACCESS_TOKEN', response.data.accessToken)
 // vuex.commit('SET_REFRESH_TOKEN', response.data.refreshToken)
 // vuex.commit('SET_CLAIM_EXPIRES', response.data.claims.exp)
 // vuex.commit('SET_DEAUTHORIZED', true)

 originalRequest.headers['Authorization'] = vuex.getters['auth/bearerToken']
 console.log('retry the request that errored out')
 return Vue.axios(originalRequest) // retry the request that errored out
 })
 .catch(error => {
 console.log('Error', error)
 if (error.response.status === 401) {
 console.log('refresh expired')
 }
 })
 }
 } else {
 if (error.response) {
 console.log('if (error.response)', error)
 let errors = error.response.data.extra.errors

 errors.forEach(function(thisError) {
 vuex.dispatch({
 type: 'flash/addFlash',
 title: thisError.message,
 flashType: 'error',
 })
 })
 }
 }
 return Promise.reject(error)
 },
 )
 */

Vue.use(VueAxios, axios)

// Vue.axios.defaults.baseURL = vuex.getters['base/baseURL']
// Vue.axios.defaults.headers.common[branding.instance] = 'true'

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

/*
 Vue.axios.interceptors.request.use(
 config => {
 config.headers['Authorization'] = vuex.getters['auth/bearerToken']
 return Promise.resolve(config)
 },
 error => {
 return Promise.reject(error)
 }
 )
 */

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

// intercept the global error
/*
 Vue.axios.interceptors.response.use(
 response => {
 // console.log('axios.config interceptors response', response)
 return Promise.resolve(response)
 },
 error => {
 // console.log('axios.config interceptors error', error)
 let originalRequest = error.config
 // console.log('originalRequest', originalRequest)

 let configUrl = error.config.url

 if (error.response.status === 403 && configUrl.includes('auth/instance/login')) {
 console.log('foo')
 } else if (error.response.status === 504) {
 vuex.dispatch({
 type: 'flash/addFlash',
 body: 'Gateway Timeout',
 text: null,
 flashType: 'error'
 })

 } else if (error.response.status === 401) {
 if (configUrl.includes('auth/jwt/refresh')) {
 console.log('401 not a refresh')
 store.dispatch('auth/setNextRedirect', router.currentRoute).then(r => {})

 router.push({
 name: 'authRedirect'
 })
 } else {
 console.log('401 refresh')

 originalRequest._retry = true
 return Vue.axios.get('/auth/jwt/refresh/' + vuex.getters['auth/refreshToken'])
 // return Vue.axios.get('/auth/jwt/refresh/' + vuex.getters.jwt.refreshToken)
 .then(response => {
 console.log('got refresh response. write down info', response.data)
 vuex.commit('auth/SET_JWT', response.data)

 // vuex.commit('SET_ACCESS_TOKEN', response.data.accessToken)
 // vuex.commit('SET_REFRESH_TOKEN', response.data.refreshToken)
 // vuex.commit('SET_CLAIM_EXPIRES', response.data.claims.exp)
 // vuex.commit('SET_DEAUTHORIZED', true)

 originalRequest.headers['Authorization'] = vuex.getters['auth/bearerToken']
 console.log('retry the request that errored out')
 return Vue.axios(originalRequest) // retry the request that errored out
 })
 .catch(error => {
 console.log('Error', error)
 if (error.response.status === 401) {
 console.log('refresh expired')
 }
 })
 }
 } else {
 if (error.response) {
 console.log('if (error.response)')
 let errors = error.response.data.extra.errors

 errors.forEach(function(thisError) {
 vuex.dispatch({
 type: 'flash/addFlash',
 body: thisError,
 text: null,
 flashType: 'error'
 })
 })
 }
 }
 return Promise.reject(error)
 }
 )
 */
